[data-a] { display: flex }
[data-a~="#"] { flex-wrap: wrap }

[data-a~="x."] { justify-content: flex-start }
[data-a~=".x."] { justify-content: center }
[data-a~=".x"] { justify-content: flex-end }
[data-a~="x.x"] { justify-content: space-between }
[data-a~=".x.x."] { justify-content: space-evenly }
[data-a~=".x..x."] { justify-content: space-around }

[data-a~="y."] { align-items: flex-start }
[data-a~=".y."] { align-items: center }
[data-a~=".y"] { align-items: flex-end }
[data-a~="y.y"] { align-items: space-between }
[data-a~=".y.y."] { align-items: space-evenly }
[data-a~=".y..y."] { align-items: space-around }
